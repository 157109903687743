<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <CCard class="border-warning">
          <CCardHeader>
            <strong>Certificados de Notas por Estudiante</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>


                <CDropdown
                    inNav
                    class="c-header-nav-items"
                    placement="bottom-end"
                    add-menu-classes="pt-0"
                    :caret="false"
                >
                  <template #toggler>
                    <CHeaderNavLink title="Ver videos de ayuda">
                      <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-hoja-ruta.mp4')"
                       class="text-info" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Hoja ruta)
                    </a>
                  </CDropdownItem>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-eliminar-nuevo.mp4')"
                       class="text text-danger" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Eliminar)
                    </a>
                  </CDropdownItem>
                </CDropdown>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalBoletin"
        size="xl"
    >
      <template #body-wrapper>
        <div class="row pl-2">
          <div class="col-2 text-center">
            <h5 class="text-center"><em class="text-danger">Certificado por<br/>gestión inscrita:</em></h5>
            <ul class="list-group" v-if="gestionesalumno.length">
              <template v-for="gestion in gestionesalumno">
                <li :class="'list-group-item'+(gestionseleccionada===gestion.gestion?' list-group-item-success':'')">
                  <input class="form-check-input me-1" type="radio" name="listGroupGestion"
                         :id="'fr'+gestion.gestion" :checked="gestion.gestion===gestionseleccionada"
                         @click="mostrarCertificado(gestion.gestion,0)">
                  <label class="form-check-label" :for="'fr'+gestion.gestion">{{ gestion.gestion }}
                  </label>
                  <span class="badge bg-primary rounded-pill float-right text-white"
                        :title="gestion.materias+' materias inscritas'">
                    {{ gestion.materias }}
                  </span>
                </li>
              </template>
            </ul>
            <p v-else>No existen datos para mostrar</p>
          </div>
          <div class="col-3 text-center px-0">
            <h5 class="text-center"><em class="text-danger">Certificado por<br/>semestre concluido:</em></h5>
            <ul class="list-group text-left" v-if="semestresalumno.length">
              <template v-for="semestre in semestresalumno">
                <li :class="'list-group-item'+(semestreseleccionado===semestre.semestre?' list-group-item-success':'')">
                  <div class="row">
                    <div class="col-6 px-0">
                      Semestre {{ semestre.semestre }}{{ semestre.grupo !== '' ? ' - ' : '' }}{{ semestre.grupo }}
                      <span class="badge bg-primary rounded-pill text-white"
                            :title="semestre.materias+' materias inscritas'">
                      {{ semestre.materias }}
                    </span>
                    </div>
                    <div class="col-4 px-0">
                      <input type="text" class="form-control form-control-sm" v-model="semestre.gestion" title="Gestión para el certificado">
                    </div>
                    <div class="col-2 px-1 text-center">
                      <button class="btn btn-outline-primary btn-sm"
                              @click="mostrarCertificadoSemestre(semestre.semestre,semestre.grupo, semestre.gestion)">
                        Ver
                      </button>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
          <div class="col-7">
            <embed ref="reportePdf" height="550" src="" type="application/pdf" title="pdf.pdf"
                   width="100%"/>
          </div>
        </div>
      </template>
      <template #header>
        <h4>{{ alumno }}</h4>
        <CButtonClose @click="modalBoletin = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </CRow>
</template>
<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";
import axios from "axios";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      modalBoletin: false,
      gestionesalumno: [],
      semestresalumno: [],
      codigo_alumno: '',
      alumno: '',
      gestionseleccionada: '',
      certificadosalumno: [],
      semestreseleccionado: '',
      gruposeleccionado: '',
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '7%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '12%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '12%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '16%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '22%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Materias aprobadas', datafield: "materias_aprobadas", width: '5%',cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Materias<br />Aprobadas</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar "});
          }
        },
        {
          text: 'Historial Academico N° Serie', datafield: "serie", width: '11%',cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Historial Academico<br/>N° Serie</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por serie"});
          }
        },
        {
          text: "", width: '15%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.estado === 'Y') ? "Certificado de notas" : "...");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.reportePdf.setAttribute('src', '')
            modalPdf.codigo_alumno = rowData.codigo_alumno;
            modalPdf.alumno = rowData.nombres + ' ' + rowData.apellido1 + ' ' + rowData.apellido2;
            modalPdf.modalBoletin = true;
            modalPdf.gestionseleccionada = ''
            axios.get(
                address + '/api/docentereportes/' + rowData.codigo_alumno + '/gestionesalumno?token=' + localStorage.getItem("api_token")
            )
                .then((response) => {
                  modalPdf.gestionesalumno = response.data;
                })
                .catch(function (error) {
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
            axios.get(
                address + '/api/docentereportes/' + rowData.codigo_alumno + '/semestresalumno?token=' + localStorage.getItem("api_token")
            )
                .then((response) => {
                  modalPdf.semestresalumno = response.data;
                })
                .catch(function (error) {
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docentereportes/boletin?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'serie', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'materias_aprobadas', type: "int"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'apellido1',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role) {
      try {
        if (!modalPdf.hasRole(role)) {
          this.$refs.gridSystem.hidecolumn(role);
        }
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    nuevoCertificado: function () {
      modalPdf.modalBoletin = false;
      modalPdf.$refs.confirm.confirm('', '¿Crear un nuevo certificado?', function () {
        modalPdf.mostrarCertificado(modalPdf.gestionseleccionada, 0)
        modalPdf.modalBoletin = true;
      }, function () {
        modalPdf.modalBoletin = true;
      })
    },
    mostrarCertificado: function (gestion, serie) {
      modalPdf.gestionseleccionada=gestion;
      modalPdf.semestreseleccionado=""
      modalPdf.$refs.reportePdf.setAttribute('src','')
      if (gestion !== '') {
        axios.get(
            address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/' + gestion + '/' + serie + '/certificadonotaspdf?token=' + localStorage.getItem("api_token"),
            {responseType: 'blob'}
        )
            .then((response) => {
              modalPdf.$refs.reportePdf.setAttribute('src', URL.createObjectURL(response.data) + "#view=FitH");
              modalPdf.listarCertificados(gestion)
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    mostrarCertificadoSemestre: function (semestre, grupo,gestion) {
      modalPdf.gestionseleccionada = ''
      modalPdf.semestreseleccionado = semestre
      modalPdf.gruposeleccionado = grupo
      modalPdf.$refs.reportePdf.setAttribute('src', '')
      if (semestre !== '') {
        grupo = grupo === '' ? '0' : grupo
        axios.get(
            address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/' + semestre + '/' + grupo + '/' + gestion + '/certificadosemestrenotaspdf?token=' + localStorage.getItem("api_token"),
            {responseType: 'blob'}
        )
            .then((response) => {
              modalPdf.$refs.reportePdf.setAttribute('src', URL.createObjectURL(response.data) + "#view=FitH");
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }else{
        alert('Primero escriba el semestre para este certificado')
      }
    },
    listarCertificados: function (gestion) {
      if (gestion !== '') {
        // axios.get(
        //     address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/' + gestion + '/listarcertificados?token=' + localStorage.getItem("api_token")
        // )
        //     .then((response) => {
        //       modalPdf.certificadosalumno = response.data
        //     })
        //     .catch(function (error) {
        //       modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        //     });
      }
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    modalPdf.showHideColumn('documentos')
    modalPdf.showHideColumn('compromisos');
    modalPdf.showHideColumn('contratos');
    modalPdf.showHideColumn('hojaruta');
  },
}
</script>